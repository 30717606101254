import React,{useEffect, useState} from 'react'
import config from '../lib/config';
import { Help } from '@material-ui/icons';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import '@metamask/legacy-web3'
import { func } from 'prop-types';
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

const WalletModal =(props)=> {
  const [account,setaccount]= useState();

  useEffect(() => {
    // Get_Activity_list();
  }, []);

  async function handleWallet() {

    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        // if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            localStorage.setItem("tfnukukpannft",'yes');
            window.location.reload(false)
          })
        // }
        }).catch((err) => {console.log(err)});
        }else{
        }
      }catch(err){console.log(err,"wallet catch err")}
     }else{
      console.log("please add metamask");
     }
  }

  async function connectTrustwallet() {
    var web3 = new Web3(config.liverpcUrls);
    var provider1 = new WalletConnectProvider({
      rpc: {
        [config.livechainid]: config.liverpcUrls
      },
      chainId: config.livechainid
    });
    provider1.on("connect", () => {
      setTimeout(() => window.$('#connect_wallet_modal').modal('hide'), 600);
      setTimeout(() => window.location.reload(false), 1200);
    });
    await provider1.enable();
    web3 = new Web3(provider1);
    let cid = await web3.eth.getAccounts();
    web3.eth.net.getId().then(async res => {
      console.log(res,"res");
      if (res == 56) {
        var getaccounts = await web3.eth.getAccounts();
        console.log(getaccounts,'accounts');
        localStorage.setItem("tfnukukpannft",'yes');
        localStorage.setItem('walltype', "trust");
        localStorage.setItem('account', getaccounts[0]);
        toast.success("wallet connected", toasterOption);
      } else {
        toast.warning("Connect to Binance Mainnet", toasterOption);
        setaccount("")
        return false;
      }
    });
  }
  

  return (
    <div className="modal fade primary_modal" id="wallet_modal" tabIndex="-1" role="dialog" aria-labelledby="wallet_modal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="wallet_modal">Connect to a wallet</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="wallet_panel" onClick={()=>handleWallet()} >
              <h2>Metamask (Web)</h2>
              <img src={require("../assets/images/icon_meta_mask.png")} alt="Icon" className="img-fluid" />
            </div>
            {/* <div className="wallet_panel">
              <h2>TrustWallet</h2>
              <img src={require("../assets/images/icon_trust_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>MathWallet</h2>
              <img src={require("../assets/images/icon_math_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>TokenPocket</h2>
              <img src={require("../assets/images/icon_pp.png")} alt="Icon" className="img-fluid" />
            </div> */}
            <div className="wallet_panel" onClick={()=>connectTrustwallet()}>
              <h2>WalletConnect (MetaMask Mobile)</h2>
              <img src={require("../assets/images/icon_wallet_connect.png")} alt="Icon" className="img-fluid" />
            </div>
            {/* <div className="wallet_panel">
              <h2>Binance Chain Wallet</h2>
              <img src={require("../assets/images/icon_binance_chain_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_modal_footer">
              <a href="#" target="_blank"><Help/><span>Learn how to connect</span></a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
)
}

export default WalletModal;