/*eslint-disable*/
import React,{useEffect} from "react";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Drawer, IconButton } from '@material-ui/core';
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import {ArrowDropDown, ArrowDropUp, Settings } from '@material-ui/icons';
import classNames from "classnames";
import Collapse from '@material-ui/core/Collapse';
import { getkukuvalue } from "../../actions/user";

const useStyles = makeStyles(styles);

export default function SidebarLinksAfterlogin(props) {
  const { classes1, handleDrawerOpen, open } = props

  const [open1, setOpen] = React.useState(true);
  const [open2, setOpen1] = React.useState(true);
  const [open3, setOpen2] = React.useState(true);
  const [kukuprice, setkukuprice] = React.useState(0.0000000);
  const handleClick = () => {
    setOpen(!open1);
  };
  const handleClick1 = () => {
    setOpen1(!open2);
  };

  const handleClick2 = () => {
    setOpen2(!open3);
  };

  const classes = useStyles();

  useEffect(() => {
    kukuvalue();
  }, []);

  const kukuvalue = async()=>{
    try{
    var {result} = await getkukuvalue();
    console.log(result,'kuku price')
    setkukuprice(result.price);
    }catch(e){
      console.error(e)
    }
  }
  return (
    <Drawer
      variant="permanent"
      className={classNames(classes1.drawer+ " dash_sidebar", {
        [classes1.drawerOpen]: open,
        [classes1.drawerClose + " minimumSidebar"]: !open
      })}
      classes={{
        paper: classNames({
          [classes1.drawerOpen]: open,
          [classes1.drawerClose]: !open
        })
      }}
      open={open}
    >
    <div className={classes1.toolbar} />
      <div className="sidebar_main">
        <List className="side_menu">
          
          <ListItem className="sideItem">
            <NavLink to="/lottery" className="sideLink">
              <img src={require("../../assets/images/menu_icon_06.png")} alt="Icon"/>Lottery
            </NavLink>
            <NavLink to={{pathname: "https://faq.decentradice.io/"}} target="_blank" className="sideLink">
              <img src={require("../../assets/images/menu_icon_06.png")} alt="Icon"/>About & FAQ
            </NavLink>

            <NavLink to={{pathname: "https://bscscan.com/address/0x55d398326f99059ff775485246999027b3197955"}} className="sideLink" target="_blank">
            <img src={require("../../assets/images/menu_icon_06.png")} alt="Icon"/>USDT on BNB
            </NavLink>

            <NavLink to={{pathname: "https://www.bscscan.com/address/0x1A20d99FaEe7bb7990F60DdB3d3f9699f88D43a9"}} className="sideLink" target="_blank">
            <img src={require("../../assets/images/menu_icon_06.png")} alt="Icon"/>Verified Contract
            </NavLink>

          </ListItem> 
             
          {/* <ListItem className="sideItem">
            <a href="javascript:void(0)" className="sideLink">
              <img src={require("../../assets/images/ido.png")} alt="Icon"/>IDO
            </a>
          </ListItem>
          <ListItem className="sideItem">
            <a href="javascript:void(0)" className="sideLink">
              <img src={require("../../assets/images/menu_icon_05.png")} alt="Icon"/>NFT
            </a>
          </ListItem> */}
              
          {/* <ListItem className="sideItem">
            <a href="javascript:void(0)" className="sideLink">
              <img src={require("../../assets/images/menu_icon_08.png")} alt="Icon"/> Contact
            </a>
          </ListItem>
          <ListItem className="sideItem">
            <a href="javascript:void(0)" className="sideLink">
              <img src={require("../../assets/images/menu_icon_09.png")} alt="Icon"/> Privacy Policy
            </a>
          </ListItem> */}
        </List>
    
        

        <div className="sidebar_footer_wrapper">
        
          <div className="siderbar_settings">
            <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classes.menuButton}>
              <Settings
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
          </div>
          <div className="sidebar_footer_top">
            {/* <div className="token_stats">
              <img src={require("../../assets/images/logo_icon.png")} alt="Icon"/> <span>${parseFloat(kukuprice).toFixed(3)}</span>
            </div> */}
            <ul className="sidebar_social_list">
              <li><a href="https://www.facebook.com/people/Decentradiceio/100092847377907/" target="_blank"><i class="fab fa-facebook"></i></a></li>
              <li><a href="https://twitter.com/DecentraDiceio" target="_blank"><i class="fab fa-twitter"></i></a></li>
              <li><a href="https://www.youtube.com/playlist?list=PLhUxkGI5px2Lm0ks641H6KnxM1qVYT4Jf" target="_blank"><i class="fab fa-youtube"></i></a></li>
            </ul>
          </div>
          {/* <div className="sidebar_footer_bottom">
            <div className="language_selector">
              <img src={require("../../assets/images/globe_icon.png")} alt="Icon"/>
              <select className="form-control" id="exampleFormControlSelect2">
                <option>EN</option>
                <option>AR</option>
                <option>NL</option>
                <option>IT</option>
                <option>RO</option>
              </select>
            </div>

            <label id="switch" className="switch">
              <input type="checkbox" id="theme_slider"/>
              <span className="theme_switcher round"></span>
            </label>

          </div> */}
        </div>
      </div>    
    </Drawer>
  );
}
       